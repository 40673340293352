<template>
  <section class="section section-standalone" id="issue-media">
    <div class="container-fluid">
      <div class="my-3">
        <div class="c-dashboard-title d-flex justify-content-between pl-2">
          <h1 class="title-font text-size-title text-uppercase mb-0">
            Issue Media
          </h1>
        </div>
        <div class="row mt-3">
          <div class="col-lg-12 col-md-12">
            <iframe
              :src="src"
              scrolling="no"
              frameborder="0"
              id="kewilayahan"
              width="100%"
              height="2500px"
            ></iframe>
          </div>
        </div>
      </div>
      <div
        class="row sumber-data mt-3"
        v-if="sumber_data != null"
        v-tooltip.top-start="{
          content: `Pic : ${
            sumber_data.pic == null ? '-' : sumber_data.pic
          } <br> 
                                Kontak : ${
                                  sumber_data.kontak == null
                                    ? '-'
                                    : sumber_data.kontak
                                }`,
          show: sumberTooltip,
          trigger: 'manual'
        }"
        style="cursor:pointer"
        v-on:mouseover="changeSumberTooltip"
        v-on:click="changeSumberTooltip"
      >
        <div class="col-md-12 ">
          <p class="text-size-normal ">
            &nbsp; Sumber Data : {{ sumber_data.sumber }} <br />
            &nbsp; Pembaharuan Terakhir :
            {{ sumber_data.updated_at == null ? '-' : sumber_data.updated_at }}
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex';

export default {
  metaInfo() {
    return {
      title: 'Issue Media'
    };
  },
  data() {
    return {
      sumberTooltip: false,
      sumber_data: {
        sumber: 'Media Kernels',
        updated_at: null
      }
    };
  },
  mounted() {
    this.load();
  },

  methods: {
    load() {
      // console.log(window.links.issueMedia);
      window.axios.post(window.base_api + 'page/issue').then(response => {
        if (response.status == 200) {
          this.sumber_data = response.data.sumber_data;
        }
      });
    },
    changeSumberTooltip() {
      this.sumberTooltip = true;
      setTimeout(() => {
        this.sumberTooltip = false;
      }, 3000);
    }
  },
  computed: {
    src() {
      return window.links['issueMedia' + (this.isDarkMode ? '_dark' : '')];
    },
    ...mapState(['isDarkMode'])
  }
};
</script>

<style scoped>
#issue-media iframe {
  height: 2500px !important;
}
</style>
